<template>
  <v-layout v-if="Home != null" column fill-height>
    <Header
      home
      titulo="La manía de tenerlo todo"
      class="font-weight-medium"
      :imagen="movil ? $store.state.headerMovil : $store.state.headerHome"
    />
    <Separador
      v-if="Home.Productos"
      titulo="Productos"
      home
      ruta="/Productos"
    />
    <CProductos v-if="Home.Productos" :articulos="Home.Productos" home />
    <!--Separador v-if="Home.HTM" titulo="Hazlo Tú Mismo" home ruta="/Hazlo-Tu-Mismo"/>
		<v-layout wrap align-center v-if="Home.HTM">
			<v-flex v-if="Home.HTM[0]" xs12 md6 :class="movil?'':'pa-3'" shrink mx-auto>
				<VideoYt :link="Home.HTM[0].video" />
			</v-flex>
			<v-flex v-if="Home.HTM[1]&&!movil" md6 :class="movil?'':'pa-3'" shrink>
				<VideoYt :link="Home.HTM[1].video" />
			</v-flex>
		</v-layout-->
    <Separador titulo="Tiendas" />
    <v-layout wrap>
      <v-flex xs12 sm6 pa-3>
        <v-hover v-slot:default="{ hover }">
          <v-card
            href="/Tiendas/Mayor"
            @click="$router.push('/Tiendas/Mayor')"
            :elevation="hover ? 12 : 2"
            class="mx-auto"
          >
            <v-img
              src="https://www.ofimania.com/img/Home/Mayor.webp"
              aspect-ratio="1.8"
            >
              <template v-slot:placeholder>
                <v-layout fill-height align-center justify-center ma-0>
                  <v-progress-circular
                    indeterminate
                    color="primary lighten-2"
                  ></v-progress-circular>
                </v-layout>
              </template>
            </v-img>
            <v-card-text class="text-center display-1"
              >Ventas al Mayor</v-card-text
            >
          </v-card>
        </v-hover>
      </v-flex>
      <v-flex xs12 sm6 pa-3>
        <v-hover v-slot:default="{ hover }">
          <v-card
            href="/Tiendas/Detal"
            @click="$router.push('/Tiendas/Detal')"
            :elevation="hover ? 12 : 2"
            class="mx-auto"
          >
            <v-img
              src="https://www.ofimania.com/img/Home/Detal.webp"
              aspect-ratio="1.8"
            >
              <template v-slot:placeholder>
                <v-layout fill-height align-center justify-center ma-0>
                  <v-progress-circular
                    indeterminate
                    color="primary lighten-2"
                  ></v-progress-circular>
                </v-layout>
              </template>
            </v-img>
            <v-card-text class="text-center display-1"
              >Tiendas al Detal</v-card-text
            >
          </v-card>
        </v-hover>
      </v-flex>
    </v-layout>
    <Separador titulo="Blog" home ruta="/Blog" />
    <v-layout my-5 wrap align-center>
      <EntradaBlog
        v-if="Home.Blog[0]"
        :id="Home.Blog[0].id"
        :titulo="Home.Blog[0].titulo"
        :texto="Home.Blog[0].texto"
        :fecha="Home.Blog[0].fecha"
        :imagen="Home.Blog[0].imagen"
        :size="1"
      />
      <EntradaBlog
        v-if="Home.Blog[1]"
        :id="Home.Blog[1].id"
        :titulo="Home.Blog[1].titulo"
        :texto="Home.Blog[1].texto"
        :fecha="Home.Blog[1].fecha"
        :imagen="Home.Blog[1].imagen"
        :size="1"
      />
    </v-layout>
    <!-- Separador titulo="Descargas" />
		<v-layout mb-5 align-center wrap justify-center>
			<v-flex xs12 sm6 md4 v-for="descarga in Home.Descargas" :key="descarga.hash" my-2>
				<Descarga :imagen="descarga.imagen" :nombre="descarga.nombre" :link="descarga.hash" />				
			</v-flex>
		</v-layout -->
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
import Descarga from "../components/Descarga.vue";
import Header from "../components/Header.vue";
import VideoYt from "../components/VideoYt.vue";
import ImagenIg from "../components/ImagenIg.vue";
import Separador from "../components/Separador.vue";
import CProductos from "../components/CProductos.vue";
import EntradaBlog from "../components/EntradaBlog.vue";

export default {
  metaInfo: {
    title: "Ofimania | La manía de tenerlo todo",
  },
  created() {
    this.$store.state.cargando = true;
    if (this.Home == null) {
      this.axios
        .get("https://www.ofimania.com/API/Home")
        .then((response) => {
          this.$store.state.Home = response.data;
          this.$store.state.cargando = false;
        })
        .catch((error) => {
          this.$store.state.cargando = false;
          this.$store.state.alerta = {
            estado: true,
            tipo: "error",
            titulo: "Error de Conexion",
            info: "Verifique su Conexion a Internet",
          };
        });
    } else {
      this.$store.state.cargando = false;
    }
  },
  data() {
    return {
      datos: null,
    };
  },
  computed: {
    ...mapState(["movil", "Home"]),
  },
  components: {
    Header,
    VideoYt,
    ImagenIg,
    Descarga,
    Separador,
    CProductos,
    EntradaBlog,
  },
};
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}
</style>
