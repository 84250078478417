<template>
  <a :href="url" style="text-decoration: none;" target="blank">
    <v-card>
      <v-img
        class="primary--text"
        :src="url + 'media'"
        width="100%"
        :aspect-ratio="1"
      >
        <v-app-bar :color="hover ? 'transparent' : ''" elevation="0">
          <v-avatar size="30px" color="white">
            <img
              src="https://www.ofimania.com/img/minilogo.png"
              alt="Avatar"
              @mouseout="hover = true"
              @mouseover="hover = false"
            />
          </v-avatar>
          <h2 v-if="!hover" class="ml-2 headline">Ofimania</h2>
        </v-app-bar>
        <template v-slot:placeholder>
          <v-layout fill-height align-center justify-center ma-0>
            <v-progress-circular
              indeterminate
              color="primary lighten-2"
            ></v-progress-circular>
          </v-layout>
        </template>
      </v-img>
      <v-card-text class="text-center">
        <span class="headline">{{ titulo }}</span>
      </v-card-text>
    </v-card>
  </a>
</template>

<script>
export default {
  data() {
    return {
      hover: true,
    };
  },
  props: {
    url: {
      type: String,
      default: "https://instagram.com/p/fA9uwTtkSN",
    },
    titulo: {
      type: String,
      default: "Titulo de la Publicacion",
    },
  },
  methods: {
    src() {
      return;
    },
  },
};
</script>
