<template>
  <v-card class="mx-auto" justify-center max-width="300">
    <v-img height="250px" :src="imagen" ma-3> </v-img>
    <v-card-title>
      {{ nombre }}
    </v-card-title>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn
        text
        :href="'https://www.ofimania.com/Descarga/' + link"
        target="blank"
        color="primary"
      >
        Descargar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    imagen: {
      type: String,
      default:
        "https://drive.google.com/uc?export=view&id=1t5qkVh4iBSqWpOowa9ij1y45ICeIMEme",
    },
    nombre: {
      type: String,
      default: "Nombre del Archivo",
    },
    link: {
      type: String,
      default: "77ab7db99988756aabeb",
    },
  },
};
</script>

<style lang="scss" scoped></style>
